
<script>

import { projects } from "@/config/api/projects";
import { debounce } from "lodash";
import { cities } from "@/config/api/cities";
import Multiselect from "vue-multiselect";
export default {
    components: { Multiselect },
    props: {
        project: {
            type: Object,
        },

    },
    data() {
        return {
            isLoading: false,
            operatingCities: {},
            cities: [],
            center: {
                lat: 24.485738,
                lng: 54.366507,
            },
            files: null,
            values: {

            },
            performAction: false,
            schema: [
                {
                    component: "div",
                    class: "row",
                    children: [
                        {
                            index: 0,
                            type: "text",
                            class: "col-6",
                            validation: "number",
                            name: "latitude",
                            label: "Latitude",
                        },
                        {
                            index: 1,
                            type: "text",
                            class: "col-6",
                            validation: "number",
                            name: "longitude",
                            label: "Longitude",
                        },
                    ]
                },
                {
                    component: "div",
                    class: "row",
                    children: [
                        {
                            index: 2,
                            type: "text",
                            class: "col-6",
                            name: "location_en",
                            label: "Area English",
                        },
                        {
                            index: 3,
                            type: "text",
                            class: "col-6",
                            name: "location_ar",
                            label: "Area Arabic",
                        },
                    ]
                },

            ],
        };
    },
    mounted() {

    },

    methods: {
        loadAllCities(text) {
            if (text) {
                this.isLoading = true;
                const api = cities.searchCity;

                api.params = {
                    search: text,
                };
                this.generateAPI(api)
                    .then((res) => {
                        this.cities = res.data.cities.map((item) => {
                            return { name: item.name, id: item._id };
                        });
                    })
                    .catch((err) => {
                        console.log("Error", err.response.data);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.cities = [
                    {
                        name: "Ab", id: "asd"
                    }
                ];
            }
        },
        handleMapClick(e) {
            this.values.latitude = e.latLng.lat()
            this.values.longitude = e.latLng.lng()
        },
        editProjectLocation() {
            if (this.project) {

                if (this.project.geoloc && this.project.geoloc.latitude) {
                    this.values.latitude = this.project.geoloc && this.project.geoloc.latitude
                    this.values.longitude = this.project.geoloc.longitude
                } else {
                    this.values.latitude = 24.466667;
                    this.values.longitude = 54.366669;
                }
                this.values.location_ar = this.project.location_ar
                this.values.location_en = this.project.location_en
                if (this.project.city)
                    this.operatingCities = { name: this.project.city.name, id: this.project.city._id };
            }
            this.$bvModal.show("add-project-location");
        },
        searchForCity(text) {
            return debounce(this.loadAllCities(text), 1000);
        },
        submitHandler(data) {
            const api = projects.model.update;

            api.data = {
                project_id: this.project.project_id,
                ...data,

            };
            if (this.operatingCities) {
                api.data['city'] = this.operatingCities.id
            }
            if (data.latitude && data.longitude) {
                api.data['geoloc.latitude'] = data.latitude;
                api.data['geoloc.longitude'] = data.longitude;
            }
            this.performAction = true;
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("Project location updated successfully", {
                        title: "Project Location",
                        variant: "success",
                        solid: true,
                    });
                    this.project = {
                        ...this.project,
                        ...data
                    }
                    this.$bvModal.hide("add-project-location");
                    this.$emit("reloadData");
                })
                .catch((err) => {
                    this.$bvToast.toast(
                        `Project location is not updated, ${err.response.data.message}`,
                        {
                            title: "Project Location",
                            variant: "danger",
                            solid: true,
                        }
                    );
                })
                .finally(() => {
                    this.performAction = false;
                });
        },
    },
};
</script>
  
<style>

</style>
<template>
    <div>
        <h5 class="font-size-14">
            Project Location :
            <button class="action-btn" @click="editProjectLocation()">
                <i class="ri-edit-line"></i>
            </button>
        </h5>
        <b-modal size="lg" id="add-project-location" title="Update Location" hide-footer>
            <FormulateForm name="UpdateLocation" v-model="values" :schema="schema" @submit="submitHandler">
                <div class="row col-12 mb-2">
                    <label for="ajax">City</label>

                    <multiselect v-model="operatingCities" id="ajax" label="name" track-by="id"
                        placeholder="Type to search" open-direction="bottom" :options="cities" :multiple="false"
                        :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false"
                        :blockKeys="['Delete']" :close-on-select="true" :options-limit="300" :limit="10"
                        :max-height="600" :show-no-results="true" @search-change="searchForCity">
                        <template slot="clear" slot-scope="props">
                            <div class="multiselect__clear" v-if="cities.length"
                                @mousedown.prevent.stop="clearAll(props.search)">
                            </div>
                        </template>
                        <span slot="noResult">Oops! No elements found. Consider changing the search
                            query.</span>
                    </multiselect>
                </div>

                <div class="row col-12">
                    <gmap-map @click="handleMapClick" :center="{ lat: values.latitude, lng: values.longitude }"
                        :zoom="15" style="height: 300px; width: 100%;">
                        <gmap-marker v-for="(m, index) in [{ lat: values.latitude, lng: values.longitude }]"
                            :key="index" :position="m" :clickable="false" :draggable="false" @click="center = m">
                        </gmap-marker>
                    </gmap-map>
                </div>
                <FormulateInput type="submit" class="float-right mt-2" :disabled="performAction">

                    <span v-if="performAction">
                        <b-spinner class="vueformulate-loader"></b-spinner>
                        <span> Updating... </span>
                    </span>
                    <span v-else>
                        <span> Update </span>
                    </span>

                </FormulateInput>
            </FormulateForm>
        </b-modal>
    </div>
</template>