<template>
  <div>
    <div style="text-align: end">
      <b-button variant="primary" v-b-modal.add-certificate pill>Add Certificate</b-button>
    </div>

    <b-modal id="add-certificate" title="Add Certificate" hide-footer>
      <FormulateForm v-model="formValues" @submit="updateCertificate" :schema="schema">
        <div class="row">
          <div class="col-md-6 pt-2">
            <label class="typo__label" for="ajax">Certificate</label>
            <multiselect v-model="selectedCertificate" label="name" track-by="_id" placeholder="Type to search"
              open-direction="bottom" :multiple="false" :searchable="true" :options="certificates"
              :loading="fetchingCertificates" :internal-search="true" :clear-on-select="false" :close-on-select="true"
              :blockKeys="['Delete']" :options-limit="100" :limit="3" :max-height="600" :show-no-results="true"
              :hide-selected="true">
            </multiselect>
          </div>
          <div class="col-md-6 pt-2">
            <label class="typo__label" for="ajax">Sub Certificate</label>
            <multiselect v-model="selectedSubCertificate" label="name" track-by="_id" placeholder="Type to search"
              open-direction="bottom" :multiple="false" :searchable="true" :options="certificateTypeData"
              :loading="fetchingSubCertificates" :internal-search="true" :clear-on-select="false"
              :close-on-select="true" :options-limit="100" :limit="3" :max-height="600" :show-no-results="true"
              :blockKeys="['Delete']" :hide-selected="true">
            </multiselect>
          </div>

        </div>
        <div>
          <FormulateInput :uploader="() => {}" type="image" name="Image" v-model="image"
            label="Select certificate to upload" help="Select a file to upload." validation="required" />
        </div>

        <div style="text-align: end" class="mt-4">
          <FormulateInput type="submit" :label="submitting ? 'Adding...' : 'Add'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { certificates } from "@/config/api/certificates";
import Multiselect from "vue-multiselect";
import { getFormData } from '../../utils/createForm';
export default {
  props: {
    project: {
      type: Object
    }
  },
  created() {
    this.loadCompanies();
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Certificate Name",
          "validation-messages": { required: "Certificate name is required" },
          validation: "required",
        },
        {
          index: 1,
          type: "date",
          name: "date_given",
          label: "Certificate Given Date",
          "validation-messages": { required: "Certificate date is required" },
          validation: "required",
        },
      ],
      formValues: {

      }, image: undefined,
      selectedCertificate: undefined,
      selectedSubCertificate: undefined,
      file: undefined,
      submitting: false,
      fetchingCertificates: false,
      fetchingSubCertificates: false,
      certificates: [],
      certificateTypeData: [],
    };
  },
  mounted() {
    this.findCertificate();
  },
  watch: {
    selectedCertificate() {
      this.loadSubCertificates()
    }
  },
  methods: {
    updateCertificate(values) {
      const data = getFormData({
        certificate_type: this.selectedSubCertificate._id,
        ...values,
        project_id: this.project.project_id,
        file: this.image.files[0].file,
      })
      const api = {
        url: "certificate/linkToProject",
        method: "POST",
        data,
      }
      this.generateAPI(api)
        .then((res) => {
          this.project.certificates = res.data.project.certificates
          console.log(res);
          this.$bvModal.hide("add-certificate");
          this.$bvToast.toast("Certificate added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.fetchingCertificates = false;
        });
    },
    findCertificate() {

      const api = certificates.get;
      if (this.project.property_type)
        api.params = { property_type: this.project.property_type._id }
      this.fetchingCertificates = true;
      this.generateAPI(api)
        .then((res) => {
          this.certificates = res.data.certificates.docs;
          if (this.certificate && this.certificate.length) {
            this.selectedCertificate = this.certificate[0];
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.fetchingCertificates = false;
        });
    },
    loadSubCertificates() {
      this.selectedSubCertificate = undefined;
      this.fetchingSubCertificates = true;
      const api = certificates.certificateTypes.get;
      this.generateAPI(api)
        .then((res) => {
          this.certificateTypeData = res.data.certificates_types.filter(
            (typesBy) => typesBy.certificate === this.selectedCertificate._id
          );
          if (this.selectedSubCertificate && this.selectedSubCertificate.length)
            this.selectedSubCertificate = this.certificateTypeData[0]
          this.fetchingSubCertificates = false;
        })
        .catch((err) => {
          this.fetchingSubCertificates = false;
          console.log(err);
        });
    },

  },
};
</script>
