<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { projects } from "@/config/api/projects";
import ProjectModal from "@/components/projects/project-specification";
import EditCustomSpec from "@/components/projects/edit-custom-specification";
import AddImages from "@/components/projects/add-images";
import { awards } from "@/config/api/awards";
import AddStamp from "@/components/projects/add-stamp";
import EditStamp from "@/components/projects/edit-stamp";
import Swal from "sweetalert2";
import AddMember from "@/components/projects/add-member";
import AddCompany from "@/components/projects/add-company";
import AddCertificate from "@/components/projects/add-certificate";
import UploadImage from "@/components/projects/add-image"
import ProjectRating from "@/components/projects/project-rating-modal"
import ProjectStatus from "@/components/projects/project-status-color"
import ProjectProgressBar from "@/components/projects/project-status-bar"
import ProjectAwardModal from "@/components/projects/project-award-modal.vue"
import ProjectLocationModal from "@/components/projects/add-location"
import moment from "moment";
import ProjectStatusChip from "@/components/projects/project-status.vue";
import ProjectId from "@/components/projects/bcc-number";
import ProjectEvidence from "@/components/projects/project-evidence";

/**
 * Product Detail Component
 */
export default {
  props: ["show"],
  components: {
    Layout, ProjectStatusChip,
    PageHeader,
    ProjectModal,
    EditCustomSpec,
    AddCertificate,
    AddImages,
    AddStamp,
    AddMember,
    AddCompany,
    EditStamp, ProjectId,
    UploadImage, ProjectAwardModal,
    ProjectRating, ProjectStatus, ProjectProgressBar, ProjectLocationModal, ProjectEvidence,
  },
  data() {
    return {
      type: null,
      projectNumber: "",
      projectInfo: undefined,
      projectAwards: [],
      evidences: [],
      selectedStamp: null,
      workedUnder: {
        user: [],
        company: [],
      },
      stamps: [],
      title: "Project Detail",
      loading: false,
      items: [
        {
          text: "View",
        },
        {
          text: "Project Detail",
          active: true,
        },
      ],
    };
  },
  async beforeMount() {
    if (this.$route.query.id) {
      this.loading = true;
      this.projectNumber = this.$route.query.id;
      await this.loadInfo();
      await this.getProjectAwards();
      await this.getListOfWorkedUnder();
      this.loading = false;
    }
  },

  methods: {
    costCalc() {
      if (this.projectInfo.project_currency && this.projectInfo.project_cost) {
        return Intl.NumberFormat('en-IN', { style: 'currency', currency: this.projectInfo.project_currency, maximumFractionDigits: 0 }).format(this.projectInfo.project_cost)

      }
      return ""
    },
    dateToYear(date) {
      if (!date)
        return "N/A";
      return moment(date).format("YYYY")
    },
    removeCoverImage() {
      Swal.fire({
        title: "Do you want to remove the image?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        preConfirm: () => {
          const api = projects.remove_cover_image;
          api.id = this.projectInfo._id;
          return this.generateAPI(api)
            .then(() => {
              this.projectInfo.cover_image = undefined;
              Swal.fire(
                "Cover image has been deleted",
                "",
                "success"
              );
            })
            .catch(() => {
              Swal.fire(
                "Unable to remove the cover image",
                "",
                "warning"
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //
        } else if (result.isDenied) {
          Swal.fire("Canceled", "", "info");
        }
      });

    },
    openCoverModal() {
      this.$bvModal.show("replace-project-image");
    },
    updateRating() {
      console.log("Updating rate");
      Swal.fire({
        title: "Do you want to update rating?",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        preConfirm: () => {
          const api = projects.updateProjectRating;
          api.id = this.projectNumber;
          return this.generateAPI(api)
            .then((res) => {
              Swal.fire(
                `Rating Updated to ${res.data.project_rating} `,
                "",
                "success"
              );
            })
            .catch((err) => {
              Swal.fire(
                `Rating is not updated ${err.response.data.message}`,
                "",
                "warning"
              );
            });
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          //
        } else if (result.isDenied) {
          Swal.fire("Rating is not updated", "", "info");
        }
      });
    },
    loadEvidence() {
      const api = {
        url: "project/getEvidence",
        method: "GET",
        params: {
          project_id: this.projectInfo._id
        }
      }
      this.generateAPI(api).then((res) => {
        this.evidences = (res.data.evidences)
      })
    },
    showStampModal() {
      this.$bvModal.show("action-Stamp");
    },
    hideStampModal() {
      this.$bvModal.hide("action-Stamp");
    },


    showCustomActionModal(type) {
      console.log(type);
      this.type = type;
      this.$bvModal.show("edit-cutom-spec");
    },
    hideCustomActionModal() {
      this.$bvModal.hide("edit-cutom-spec");
    },
    uploadImages() {
      this.$bvModal.show("add-images");

    },

    async reloadData() {
      this.loading = true;
      this.loadInfo();
      this.loading = false;
    },
    async loadInfo() {
      if (this.$route.query.type === "new") {
        try {
          const api = projects.model.search;
          api.id = this.projectNumber;
          let res = await this.generateAPI(api);
          this.projectInfo = res.data.project;
          this.stamps = res.data;
        } catch (error) {
          console.error(error.response);
        }
      } else
        try {
          const api = projects.model.search;
          api.id = this.projectNumber;
          let res = await this.generateAPI(api);
          this.projectInfo = res.data.project;
          this.stamps = res.data.stamp;
        } catch (error) {
          console.error(error.response);
        }
      this.loadEvidence()
    },
    async getProjectAwards() {
      try {
        const api = awards.projects.getByProjectId;
        api.id = this.projectInfo._id;
        let res = await this.generateAPI(api);
        this.projectAwards = res.data.models;
      } catch (error) {
        console.error(error);
      }
    },


    delCertificate(certificate_id) {

      let api = {
        url: "certificate/unlinkFromProject",
        method: "POST",
        data: {
          project_id: this.projectInfo.project_id,
          certificate_id,
        },
      };
      this.generateAPI(api)
        .then(() => {
          this.loadInfo();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //1
    hideCertificate(certificate_id) {
      console.log(projects);
      let api = projects.model.hideCertificate;
      api.data = {
        id: this.projectInfo._id,
        certificate_id,
      };
      this.generateAPI(api)
        .then(() => {
          this.loadInfo();
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //2
    unhideCertificate(certificate_id) {
      let api = projects.model.unhideCertificate;
      api.data = {
        certificate_id,
        id: this.projectInfo._id,
      };
      this.generateAPI(api)
        .then((res) => {
          console.log(res);
          this.loadInfo();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editSpecification() {
      this.$bvModal.show("edit-cutom-spec");
    },
    addProjectAward() {
      this.$bvModal.show("project-add-award");
    },
    appendImages(data) {
      this.$bvModal.hide("add-images");
      this.projectInfo.images = data.project.images;
    },
    getListOfWorkedUnder() {
      const api = projects.getListOfWorkedUnder;
      api.params = { project_id: this.projectNumber };
      this.generateAPI(api).then((res) => {
        res.data.message.forEach((item) => {
          item.company
            ? this.workedUnder.company.push({
              value: item.company._id,
              label:
                item.company.display_name ||
                item.company.legal_name ||
                item.company.company_email,
            })
            : this.workedUnder.user.push({
              value: item.user._id,
              label:
                item.user.email || item.user.name || item.user.first_name,
            });
        });
      });
    },
    memberAdded(members) {
      this.projectInfo.team_members = members;
    },
    companyAdded(companies) {
      this.projectInfo.companies = companies;
    },
    editStamp(stamp) {
      this.selectedStamp = stamp;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div v-if="loading">
      <div style="text-align: center" class="mt-5 mb-5">
        <pulse-loader color="#505d69" :loading="true"></pulse-loader>
      </div>
    </div>
    <div class="row" v-else>
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="product-desc">
              <b-tabs class="nav-tabs-custom" content-class="border border-top-0 p-4">
                <b-tab title="General">
                  <div class="Container">
                    <!-- Information section -->
                    <div class="row">
                      <div class="col-4 product-detail">
                        <div class="relative">
                          <div style="text-align: end">
                            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
                              <template #button-content>
                                <i class="ri-settings-3-line" style="color:white"></i>
                              </template>
                              <b-dropdown-item @click="openCoverModal()">Replace</b-dropdown-item>
                              <b-dropdown-item @click="removeCoverImage()">Remove</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                          <div class="tab-pane fade show active" id="product-1" role="tabpanel">
                            <div class="product-img">
                              <img v-if="projectInfo.cover_image" id="expandedImg" :src="projectInfo.cover_image" alt
                                class="img-fluid mx-auto d-block" />
                              <img v-else id="expandedImg" src="@/assets/mawj/Logo.png" alt
                                class="img-fluid mx-auto d-block" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <div class="d-inline-flex">
                            <div class="text-muted mr-3">
                              <span :class="`mdi mdi-star ${parseInt(projectInfo.rating) >= i
                              ? 'text-warning'
                              : ''
                              }`" v-for="i in 10" :key="i">
                              </span>
                              <br>
                              Views: ( {{ projectInfo.views }} )
                            </div>
                            <div class="text-muted">
                              <ProjectRating :project="projectInfo" :awards="projectAwards"></ProjectRating>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            Status
                          </div>
                          <div class="ml-2">
                            <ProjectStatus :evidences="evidences" :project="projectInfo"></ProjectStatus>
                            
                          </div>
                        </div>
                        <div class="row">
                          <div>
                            Approval:
                          </div>
                          <div class="ml-2">
                            <ProjectStatusChip :projectId="projectInfo.project_id"
                              :isApproved="projectInfo.is_approved"></ProjectStatusChip>
                          </div>
                        </div>
                        <div class="row" v-if="projectInfo.createdBy">
                          <a :href="'/user-info?id=' + projectInfo.createdBy._id">
                            Created By: {{ projectInfo.createdBy.name || projectInfo.createdBy.first_name }}
                          </a>
                          <project-evidence name="Project Created By" keyname="prj-created-by" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </div>
                        <ProjectProgressBar :project="projectInfo"></ProjectProgressBar>
                      </div>
                    </div>
                    <!-- End Information section -->

                    <!-- Project ID Section -->
                    <div class="row mt-2">

                      <div class="col-12">
                        <div class="col-3 relative">
                          <div style="text-align: end;">
                            <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret right>
                              <template #button-content>
                                <i class="ri-settings-3-line" style="color:black"></i>
                              </template>
                              <b-dropdown-item :to="{
                                path: '/edit-projects',
                                query: {
                                  id: projectInfo && projectInfo.project_id,
                                },
                              }">Edit</b-dropdown-item>
                              <b-dropdown-item @click="updateRating">Update Rating</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                        <ProjectId :project="projectInfo" :evidences="evidences"></ProjectId>
                      </div>
                      <div class="col-12">
                        Name : {{ projectInfo.name_en }} - {{ projectInfo.name_ar }}
                      </div>
                      <div class="col-12">
                        Building Type : {{ projectInfo.sub_project_type && projectInfo.sub_project_type.main_property &&
                        projectInfo.sub_project_type.main_property.name

                        }} -
                        {{ projectInfo.sub_project_type && projectInfo.sub_project_type.name }}
                      </div>

                    </div>
                    <!-- End Project ID Section -->
                    <hr class="my-4" />
                    <!-- Project Specifications -->
                    <div class="col-md-6">
                      <ProjectModal :selectedProject="projectInfo" type="other" v-on:reloadData="loadInfo" />
                      <div class="row">
                        <div class="col-6">
                          <i class="mdi mdi-timer text-primary mr-1 align-middle font-size-16"></i> Year Start :
                          {{ dateToYear(projectInfo.start_date) }}
                          <project-evidence name="Project Start Date" keyname="prj-start-date" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </div>
                        <div class="col-6">
                          <i class="mdi mdi-timer text-primary mr-1 align-middle font-size-16"></i> Year End :
                          {{ dateToYear(projectInfo.end_date) }}
                          <project-evidence name="Project End Date" keyname="prj-end-date" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </div>
                      </div>
                      <ul class="list-unstyled product-desc-list">
                        <li>
                          <i class="mdi mdi-map-marker-distance text-primary mr-1 align-middle font-size-16"></i>
                          Area : {{ projectInfo.project_area }} sqft.
                          <project-evidence name="Project Area" keyname="prj-area" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </li>
                        <li>
                          <i class="mdi mdi-home-floor-3 text-primary mr-1 align-middle font-size-16"></i>
                          Number of Floors : {{ projectInfo.num_of_floors }}
                          <project-evidence name="Project Number of Floors" keyname="prj-floors" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </li>
                        <li>
                          <i class="mdi mdi-cash-usd-outline text-primary mr-1 align-middle font-size-16"></i>
                          Cost :{{ costCalc() }}
                          <project-evidence name="Project Cost" keyname="prj-cost" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </li>


                      </ul>
                    </div>
                    <!-- End Project Specifications -->
                    <hr class="my-4" />
                    <!-- Custom Specification -->

                    <div class="col-md-6">
                      <h5 class="font-size-14">
                        Custom Specification :
                        <button class="action-btn" @click="showCustomActionModal()">
                          <i class="ri-edit-line"></i>
                        </button>
                      </h5>
                      <ul class="list-unstyled product-desc-list">
                        <li v-for="(
                              value, key
                            ) in projectInfo.custom_specifications" :key="key._id">
                          <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
                          {{ key }}
                          :
                          {{ value }}
                          <project-evidence :name="`Project ${key}`" :keyname="`prj-custom-${key}`" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </li>
                      </ul>
                    </div>
                    <!-- End Custom Specification -->

                    <hr class="my-4" />
                    <!-- Project Location -->
                    <div class="col-md-6">

                      <ProjectLocationModal :project="projectInfo" v-on:reloadData="loadInfo"></ProjectLocationModal>

                      <ul class="list-unstyled product-desc-list">
                        <li>
                          <i class="mdi mdi-map-marker text-primary mr-1 align-middle font-size-16"></i>
                          City : {{ projectInfo.city && projectInfo.city.country && projectInfo.city.country.name }} ,
                          {{ projectInfo.city && projectInfo.city.name }}
                          <project-evidence name="Project City" keyname="prj-city" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </li>
                        <li>
                          <i class="mdi mdi-map-marker text-primary mr-1 align-middle font-size-16"></i>
                          Area : {{ projectInfo.location_ar }} - {{ projectInfo.location_en }}
                          <project-evidence name="Project location" keyname="prj-location" :project="projectInfo"
                            :evidences="evidences"></project-evidence>
                        </li>
                        <li>
                          <i class="mdi mdi-map-marker-radius text-primary mr-1 align-middle font-size-16"></i>
                          Map : {{ projectInfo.geoloc && projectInfo.geoloc.latitude }} , {{ projectInfo.geoloc &&
                          projectInfo.geoloc.longitude}}
                        </li>
                        <li>
                          <gmap-map v-if="projectInfo.geoloc"
                            :center="{ lat: projectInfo.geoloc.latitude, lng: projectInfo.geoloc.longitude }" :zoom="15"
                            style="height: 300px">
                            <gmap-marker
                              v-for="(m, index) in [{ lat: projectInfo.geoloc.latitude, lng: projectInfo.geoloc.longitude }]"
                              :key="index" :position="m" :clickable="false" :draggable="false" @click="center = m">
                            </gmap-marker>
                          </gmap-map>
                        </li>


                      </ul>
                    </div>
                    <!-- End Project Location -->

                  </div>
                </b-tab>
                <b-tab title="Images">
                  <div class="row mb-2">
                    <button style="text-align: end" type="button" class="button" @click="uploadImages()">
                      <span class="button__text">ADD Image</span>
                    </button>
                  </div>
                  <div>
                    <div class="row">
                      <b-container fluid class="p-4 bg-dark" v-if="projectInfo.images && projectInfo.images.length">
                        <div class="row">
                          <div class="col-2" v-for="(item, index) in projectInfo.images" :key="index">
                            <a :href="item.url" target="_blank">
                              <b-img thumbnail fluid :src="item.url" alt="Images">
                              </b-img>
                            </a>
                            <span class="badge badge-primary mt-2">{{
                            item.type
                            }}</span>
                            <p style="color: white">{{ item.description }}</p>
                          </div>
                        </div>
                      </b-container>
                    </div>
                  </div>
                </b-tab>
                <b-tab title="Members">
                  <add-member :workedUnder="workedUnder" v-on:member-added="memberAdded" />
                  <div class="row">
                    <div class="col-1 project-team-member" v-for="member in projectInfo.team_members" :key="member._id">
                      <div v-if="member.user">

                        <a :href="'/user-info?id=' + member.user._id">
                          <img v-if="(member.user.cover_image)" :src="member.user.cover_image" alt class="avatar-md" />
                          <img v-else src="@/assets/mawj/logo.svg" alt class="avatar-md" />

                          <p>{{ member.user.name || member.name || "Not Provided" }}</p>
                        </a>
                      </div>
                      <div v-else>
                        <img v-if="(member.picture)" :src="member.picture" alt class="avatar-md" />
                        <img v-else src="@/assets/mawj/logo.svg" alt class="avatar-md" />
                        <p>{{ member.name || "Not Provided" }}</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="projectInfo.team_members.length == 0">
                    No Members
                  </div>
                </b-tab>

                <!--1-->
                <b-tab title="Certificates">
                  <add-certificate :project="projectInfo" v-on:compnay-added="companyAdded" />
                  <div class="row mt-3" v-if="projectInfo.certificates">
                    <div class="card-shadow col-xl-2 col-lg-2 col-md-3 col-4"
                      v-for="certificates in projectInfo.certificates" :key="certificates._id">
                      <div class="product-color-item">
                        <img :src="certificates.certificate_type_id.image" alt class="avatar-md" />
                      </div>
                      <p>{{ certificates.certificate_type_id.name }}
                        <br>{{certificates.certificate_type_id.certificate.name}}
                        <br>
                        {{
                        certificates.certificate_type_id.description }}
                      </p>
                      <div class="row ml-2">
                        <div class="action-btn" @click.prevent="delCertificate(certificates._id)">
                          <i class="mdi mdi-delete-outline"></i>
                        </div>
                        <a class="action-btn" @click.prevent="hideCertificate(certificates._id)"
                          v-if="certificates.status == 'Visible'">
                          <i class="mdi mdi-eye-off"></i>
                        </a>

                        <button class="action-btn" @click.prevent="unhideCertificate(certificates._id)" v-else>
                          <i class="mdi mdi-eye-outline"></i>
                        </button>
                        <a class="ml-1" v-if="certificates.certificate_document"
                          :href="certificates.certificate_document" target="_blank"><i
                            class="dripicons-cloud-download"></i> </a>
                      </div>

                    </div>

                  </div>
                  <div class="col-md-6" v-if="projectInfo.certificates.length == 0">
                    No Certificates
                  </div>
                </b-tab>
                <!--2-->
                <b-tab title="Companies">
                  <add-company v-on:compnay-added="companyAdded" />
                  <div class="row mt-3 columns is-multiline ">

                    <router-link class="col-xs-4 col-sm-3 col-md-2 col-lg-2 col-xl-1  col-4 text-center ml-1"
                      :to="{ path: `/company-info?id=${Companies.company._id}` }"
                      v-for="Companies in projectInfo.companies" :key="Companies._id">

                      <div class="product-color-item">
                        <img v-if="Companies.company.cover_image" :src="Companies.company.cover_image" alt class=""
                          height="80" width="80" />
                        <img v-else id="expandedImg" src="@/assets/mawj/logo.svg" alt class="img-fluid mx-auto d-block"
                          height="80" width="80" />
                      </div>
                      <p>
                        {{ Companies.company && Companies.company.display_name }} <br />
                        {{ Companies.role && Companies.role.toUpperCase() }}
                      </p>
                    </router-link>
                  </div>

                  <div class="col-md-6" v-if="projectInfo.companies.length == 0">
                    No Members
                  </div>
                </b-tab>
                <!--3-->

                <!-- 4 -->

                <b-tab title="Stamps">
                  <div class="col-md-12">
                    <b-button id="addCertificates" @click="showStampModal" pill variant="primary">Add Stamp</b-button>
                    <div class="product-color mt-3">
                      <a @click="editStamp(stamp)" v-for="stamp in stamps" :key="stamp._id">
                        <div class="product-color-item">
                          <img v-if="stamp.image" :src="stamp.image" alt class="avatar-md" />
                          <!-- <i class="ri-delete-bin-5-line"></i> -->
                        </div>
                        <p>
                          {{ stamp.name }} <br />
                          {{ stamp.description }}
                        </p>
                      </a>
                    </div>
                  </div>
                  <div class="col-md-6" v-if="projectInfo.companies.length == 0">
                    No Members
                  </div>
                </b-tab>
                <b-tab title="Awards">
                  <b-button pill variant="primary" @click="addProjectAward()">Add Award</b-button>
                  <div class="row">
                    <div class="col-2  row-height" v-for="award, index in projectAwards" :key="index">
                      <div class="m-2 card card-body border-all">

                        <img class="card " v-if="award.award_document" :src="award.award_document" height="100px"
                          width="100px" alt="Project Image">
                        <img v-else src="@/assets/mawj/logo_white.svg" height="100px" width="100px" alt="Project Image"
                          class="card placeholder-img" />
                        <a v-if="award.award_document" :href="award.award_document" target="_blank"><i
                            class="dripicons-cloud-download"></i> </a>
                        <h4 class="card-title">{{ award.name }}</h4>
                        <p class="card-title-desc">{{ award.description || '-' }}</p>
                        <p class="card-title-desc" v-if="award.date_received">{{
                        moment(award.date_received).format("LL") }}</p>
                        <p class="card-title-desc " v-else>-</p>
                      </div>


                    </div>
                  </div>

                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>

    </div>
    <!-- end row -->

    <!-- v-on:resetModal="projectInfo = null" -->
    <AddStamp :selectedProject="projectInfo" v-on:closeModal="hideStampModal" v-on:reloadData="loadInfo" />
    <EditStamp :selectedStamp="selectedStamp" @resetModal="selectedStamp = null" v-on:reloadData="loadInfo" />
    <EditCustomSpec v-on:closeModal="hideCustomActionModal" v-if="projectInfo" :selectedProject="projectInfo" />
    <AddImages :selectedProject="projectInfo" v-on:closeModal="appendImages" />
    <b-modal size="md" id="replace-project-image" title="Upload Project Image" hide-footer class="mb-5">
      <UploadImage :project="projectInfo" />
    </b-modal>
    <ProjectAwardModal :project="projectInfo"></ProjectAwardModal>

    <!-- end row -->
  </Layout>
</template>
<style>
.row-height {
  float: none;
  display: grid;
  vertical-align: top;
}

.border-all {
  border: 1px solid black;
}

.img-thumbnail {
  height: 250px;
  width: 250px;
}

.relative {
  position: absolute;
  z-index: 10;
  right: 0px;
  color: white;
  top: 0px;
}

.button {
  height: 50px;
  padding: 0;
  background: #505d69;
  border: none;
  outline: none;
  border-radius: 5px;
  overflow: hidden;
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

.button:hover {
  background: #505d69;
}

.button:active {
  background: #505d69;
}

.button__text,
.button__icon {
  display: inline-flex;
  align-items: center;
  padding: 0 24px;
  color: #fff;
  height: 100%;
}

.button__icon {
  font-size: 1.5em;
  background: rgba(0, 0, 0, 0.08);
}

.project-team-member {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 12px;
}

.project-team-member img {
  padding: 4px;
  margin: 8px;
  border: 2px solid #eff2f7;
  border-radius: 4px;
}

.project-team-member a:hover img {
  border-color: #505d69;
}
</style>
