<template>
  <b-modal id="edit-cutom-spec" title="Custom Specification" hide-footer>
    <ul class="list-unstyled product-desc-list" v-if="customSpec">
      <li v-for="(key, value) in customSpec" :key="value">
        <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
        {{ value }}
        :
        {{ key }}
        <i
          class="mdi mdi-delete-outline mr-1 align-middle"
          v-on:click="remove(true, value)"
        ></i>
      </li>
      <li
        :v-if="selectedProject"
        v-for="(key, value) in selectedProject.custom_specifications"
        :key="value"
      >
        <i class="mdi mdi-circle-medium mr-1 align-middle"></i>
        {{ value }}
        :
        {{ key }}
        <i
          class="mdi mdi-delete-outline mr-1 align-middle"
          v-on:click="remove(false, value)"
        ></i>
      </li>
    </ul>
    <FormulateForm
      name="PromoteTopAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Updating... </span>
        </span>
        <span v-else> Save </span>
      </FormulateInput>
      <button type="button" class="btn btn-primary" @click="addNewItem()">
        Add
      </button>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { projects } from "@/config/api/projects";
export default {
  props: ["selectedProject"],
  data() {
    return {
      values: {},
      customSpec: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "keyname",
          label: "Type",
        },
        {
          index: 1,
          type: "text",
          name: "value",
          label: "Value",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    remove(temp, data) {
      if (temp) {
        this.$delete(this.customSpec, data);
      } else {
        this.$delete(this.selectedProject.custom_specifications, data);
      }
    },
    closeModel() {
      this.$bvModal.hide("edit-cutom-spec");
    },
    addNewItem() {
      if (this.values.value && this.values.keyname) {
        let k = this.values.keyname;
        let v = this.values.value;
        this.customSpec[k] = v;
      }
      this.values.keyname = "";
      this.values.value = "";
    },
    async submitHandler() {
      if (this.values.keyname) {
        this.addNewItem();
      }
      const api = projects.model.update;

      api.data = {
        project_id: this.selectedProject.project_id,
        custom_specifications: {
          ...this.selectedProject.custom_specifications,
          ...this.customSpec,
        },
      };

      this.performAction = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Project updated successfully", {
            title: "Project Update",
            variant: "success",
            solid: true,
          });
          this.closeModel();
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Project is not updated, ${err.response.data.message}`,
            {
              title: "Project Update",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },

  computed: {},
};
</script>

<style>
</style>