<template>
  <div>
    <div>
      <div  style="text-align: end" class="row ml-1"
        v-if="project && project.status_code">

       <span v-b-modal.project-color-status> {{ project.status_code.name || 'Unknown' }}</span>
        <project-evidence name="Project Status" keyname="prj-status" :project="project" :evidences="evidences">
        </project-evidence>

      </div>
      <div v-else v-b-modal.project-color-status>
        Project status is missing
      </div>
    </div>

    <b-modal id="project-color-status" title="Status" hide-footer>
      <FormulateForm v-model="formValues" @submit="updatedStatus">
        <div class="row">
          <div class="col-md-6 pt-2">
            <label class="typo__label" for="ajax">Project status code</label>
            <multiselect v-model="selectedStatus" label="name" track-by="_id" placeholder="Type to search"
              open-direction="bottom" :multiple="false" :searchable="true" :options="status_codes"
              :loading="fetchingStatus" :internal-search="true" :clear-on-select="false" :close-on-select="true"
              :options-limit="100" :limit="3" :max-height="600" :show-no-results="true" :hide-selected="true">
              <template slot="option" slot-scope="props">
                <div class="columns ">
                  <div class="column is-2"
                    :style="`height: 20px;width: 20px;background-color: ${props.option.color};color: #000;`"></div>
                </div>
                <div class="option__desc column is-6 p-2"><span class="option__title">{{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
          <div class="col-6"> <label class="typo__label" for="ajax">Current Status</label>
            <div v-if="project && (!!project.status) && project.status.length">
              {{ JSON.parse(project.status).name }}
            </div>
            <div v-else>
              Unknown
            </div>
          </div>
        </div>

        <div style="text-align: end" class="mt-4">
          <FormulateInput type="submit" :label="submitting ? 'Saving...' : 'Save'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { projects } from "@/config/api/projects";
import ProjectEvidence from "@/components/projects/project-evidence";

export default {
  props: {
    project: {
      type: Object
    },
    evidences: {
      type: Array
    }
  },
  mounted() {
    this.loadStatus()
  },
  components: {
    Multiselect, ProjectEvidence
  },
  data() {
    return {
      formValues: {},
      selectedStatus: undefined,
      file: undefined,
      submitting: false,
      fetchingStatus: false,
      status_codes: [],
    };
  },
  methods: {
    updatedStatus() {
      this.submitting = true
      const api = projects.changeStatus
      api.id = this.project.project_id;
      api.data = {
        project_status: this.selectedStatus._id
      }

      this.generateAPI(api)
        .then((res) => {
          this.project.status_code = res.data.message.status_code
          this.$bvModal.hide(`project-color-status`);
        })
        .catch(() => {

        })
        .finally(() => {
          this.submitting = false;

        });
    },
    loadStatus() {
      this.fetchingStatus = true
      const api = projects.projectStatus

      this.generateAPI(api)
        .then((res) => {
          this.status_codes = res.data.models
        })
        .catch(() => {

        })
        .finally(() => {
          this.fetchingStatus = false;

        });
    },
  },
};
</script>
<style scoped>
.status-code {
  height: 20px;
  width: 20px;
  background-color: #000;
  color: #000;
}
</style>