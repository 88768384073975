<template>
  <div>
    <div style="text-align: end">
      <b-button variant="primary" v-b-modal.add-member pill
        >Add Member</b-button
      >
    </div>

    <b-modal id="add-member" title="Add Member" hide-footer>
      <FormulateForm
        v-model="formValues"
        @submit="addMember"
      >
        <div class="row">
          <div class="col-md-6">
            <FormulateInput
              name="name"
              type="text"
              label="Name"
              placeholder="Name here"
              validation="required"
            />
          </div>
          <div class="col-md-6">
            <FormulateInput
              name="email"
              type="email"
              label="Email"
              placeholder="Email here"
              validation="required"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <FormulateInput
              name="role"
              type="text"
              label="Role"
              placeholder="Role here"
            />
          </div>
          <div class="col-md-6">
            <FormulateInput
              name="worked_under"
              type="select"
              :option-groups="workedUnder"
              label="Worked under"
              placeholder="Worked under here"
              validation="required"
            />
          </div>
        </div>
        <b-form-group label="Profile Pic" class="mt-2">
          <b-form-file
            accept="image/jpeg, image/png, image/gif"
            v-model="file"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          ></b-form-file>
        </b-form-group>
        <div style="text-align: end" class="mt-4">
          <FormulateInput
            type="submit"
            :label="submitting ? 'Addding...' : 'Add'"
          />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { projects } from "@/config/api/projects";
import { getFormData } from "@/utils/createForm";
import Swal from "sweetalert2";
export default {
  props: ["workedUnder"],
  data() {
    return {
      formValues: {},
      file: undefined,
      submitting: false,
    };
  },
  methods: {
    addMember() {
      const data = {
        ...this.formValues,
        project_id: this.$route.query.id,
        file: this.file,
        submitting: false,
      };
      const api = projects.addMember;
      api.data = getFormData(data);
      this.submitting = true;
      this.generateAPI(api)
        .then((res) => {
          this.$bvModal.hide('add-member')
          this.$emit("member-added",res.data.project.team_members)
          Swal.fire("Added", "Member added successfully.", "success");
        })
        .catch((err) => {
          console.log(err.response.data);
          Swal.fire(
            "Not Added!",
            `Member is not added, ${err.response.data.message}`,
            "error"
          );
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>
