<template>
  <b-modal id="add-images" @hidden="$emit('resetModal')" title="Upload Images" hide-footer>
    <FormulateForm name="ImageAddAction" v-model="values" :schema="schema" @submit="submitHandler">
      <FormulateInput type="image" name="files" upload-behavior="delayed" v-model="files" :uploader="() => { }"
        label="Upload Images" help="Select a png or jpg to upload."
        validation="required:mime:image/jpeg,image/png,image/webp" multiple />
      <FormulateInput type="submit" class="float-right" :disabled="performAction">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Updating... </span>
        </span>
        <span v-else>
          <span> Update </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>
<script>
// alihassan
import { projects } from "@/config/api/projects";
import { getFormData } from "@/utils/createForm";

export default {
  props: ["selectedProject"],
  data() {
    return {
      files: null,
      values: {},
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "description",
          label: "Description",
        },
        {
          index: 1,
          type: "select",
          placeholder: "Select an Type",
          name: "type",
          options: { interior: "Interior", exterior: "Exterior", drawing: "Drawing", cover_image: "Cover Image" },
          label: "What type?",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      const api = projects.model.addimgImages;
      api.id = this.selectedProject._id;

      const formdata = getFormData({
        type: data.type,
        description: data.description,
      });
      console.log(formdata);
      let i = 0;
      this.files.files.forEach((ele) => {
        formdata.append(`files${i}`, ele.file);
        i = i + 1;
      });

      api.data = formdata;
      this.performAction = true;
      this.generateMultipartAPI(api)
        .then((res) => {
          this.$formulate.reset('ImageAddAction')
          this.$bvToast.toast("Project picture added successfully", {
            title: "Project picture",
            variant: "success",
            solid: true,
          });
          this.$emit('closeModal', res.data)
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Project picture is not added, ${err.response.data.message}`,
            {
              title: "Project picture",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
};
</script>

<style>

</style>