<template>
  <div>
    <div style="text-align: end">
      <b-button variant="primary" v-b-modal.add-company pill>Add Company</b-button>
    </div>

    <b-modal id="add-company" title="Add Company" hide-footer>
      <FormulateForm v-model="formValues" @submit="addCompany">
        <div class="row">
          <div class="col-md-6 pt-2">
            <label class="typo__label" for="ajax">Company</label>
            <multiselect v-model="selectedCompany" label="name" track-by="id" placeholder="Type to search"
              open-direction="bottom" :multiple="false" :searchable="true" :options="companies"
              :loading="fetchingCompany" :internal-search="false" :clear-on-select="false" :close-on-select="true"
              :options-limit="100" :limit="3" :max-height="600" :show-no-results="true" :hide-selected="true"
              :blockKeys="['Delete']" @search-change="findCompany">
            </multiselect>
          </div>
          <div class="col-md-6">
            <FormulateInput name="role" type="select" :options="[
              'financed_by',
              'owner',
              'contractor',
              'sub_contractor',
              'consultant',
            ]" label="Role" placeholder="Role here" />
          </div>
        </div>

        <div style="text-align: end" class="mt-4">
          <FormulateInput type="submit" :label="submitting ? 'Addding...' : 'Add'" />
        </div>
      </FormulateForm>
    </b-modal>
  </div>
</template>

<script>
import { projects } from "@/config/api/projects";
import { companies } from "@/config/api/companies";
import Multiselect from "vue-multiselect";
import { getFormData } from "@/utils/createForm";
import Swal from "sweetalert2";
export default {
  created() {
    this.loadCompanies();
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      formValues: {},
      selectedCompany: undefined,
      file: undefined,
      submitting: false,
      fetchingCompany: false,
      companies: [],
    };
  },
  methods: {
    findCompany(val) {
      this.loadCompanies(val);
    },
    loadCompanies(val = "MVP") {
      const api = companies.searchCompany;
      api.params = {
        name: val,
      };
      this.fetchingCompany = true;
      this.generateAPI(api)
        .then((res) => {
          this.companies = res.data.companies.map((item) => {
            return {
              id: item._id,
              name: item.display_name || item.legal_name || item.company_email,
            };
          });
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally(() => {
          this.fetchingCompany = false;
        });
    },
    addCompany() {
      if (this.selectedCompany) {
        const data = {
          ...this.formValues,
          project_id: this.$route.query.id,
          company: this.selectedCompany.id,
        };
        const api = projects.addCompany;
        api.data = getFormData(data);
        console.log(api);
        this.submitting = true;
        this.generateAPI(api)
          .then((res) => {
            console.log(res.data);
            this.$bvModal.hide("add-company");
            this.$emit("compnay-added", res.data.project.companies);
            Swal.fire("Added", "company added successfully.", "success");
          })
          .catch((err) => {
            Swal.fire(
              "Not Added!",
              `Company is not added, ${err.response.data.message}`,
              "error"
            );
          })
          .finally(() => {
            this.submitting = false;
          });
      } else {
        Swal.fire(
          "Company is not selected",
          "Please choose a company!",
          "error"
        );
        return;
      }
    },
  },
};
</script>
