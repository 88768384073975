<script>
// import { certificates } from "@/config/api/certificates";
import { stamps } from "@/config/api/stamps";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["selectedProject"],
  data() {
    return {
      values: {},
      image: null,
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          name: "description",
          label: "Description",
          validation: "required",
        },
        {
          index: 2,
          type: "date",
          name: "expiry_date",
          label: "Expiry date",
        },
      ],
    };
  },
  methods: {
    async submitHandler(data) {
      const formdata = getFormData({
        project_id: this.selectedProject._id,
        file: this.image.files[0].file,
        name: data.name,
        description: data.description,
        expiry_date: data.expiry_date,
      });
      this.addStamp(formdata);
      //   }
    },
    addStamp(data) {
      this.performAction = true;

      const api = stamps.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Stamp added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
          this.$emit("closeModal");
        })
        .catch((err) => {
          this.$bvToast.toast("Stamp is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedProject(val) {
      if (val) {
        this.values = { ...val };
      }
    },
  },
};
</script>

<template>
  <b-modal
    id="action-Stamp"
    @hidden="$emit('resetModal')"
    title="Add Stamp"
    hide-footer
  >
    <FormulateForm
      name="CertificateAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput
        type="image"
        name="Image"
        v-model="image"
        label="Select file to upload"
        help="Select a png or jpg to upload."
        validation="required:mime:image/jpeg,image/png"
      />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Adding... </span>
        </span>
        <span v-else>
          <span> Add </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>
