<template>
    <div>
        <button type="button " class="no-bg-btn" @click="changeStatus()">
            <div class="badge badge-soft-success font-size-12" v-if="isApproved">
                Approved
            </div>
            <div class="badge badge-soft-warning font-size-12" v-else>
                Pending
            </div>
        </button>
        <b-modal size="md" :id="`project-status-code${projectId}`" title="Project Status" hide-footer>
            <div>
                <div class="row text-align-center col-12 mb-3  justify-content-center">
                    Current Status: {{  isApproved ? 'Approved' : "Pending"  }}
                </div>
                <div class="row  justify-content-center">
                    <div class="col-3">
                        <b-button variant="success" @click="updateStatus(true)"> {{  approving ? "Approving....." :
                        "Approve"

                            }}
                        </b-button>
                    </div>
                    <div class="col-3">
                        <b-button variant="warning" @click="updateStatus(false)"> {{  pending ? "Pending....." :
                        "Pending"

                            }}
                        </b-button>
                    </div>
                </div>
            </div>

        </b-modal>
    </div>
</template>
  
  <script>
  import { projects } from "@/config/api/projects";
  export default {
      props: {
          isApproved: {
              type: Boolean,
          },
          projectId: { type: String }
      },
      created() {
      },
      components: {
      },
      data() {
          return {
              approving: false,
              pending: false,
          }
      },
      methods: {
          updateStatus(is_verified) {
              if (is_verified) {
                  this.approving = true;
                  this.pending = false;
              } else {
                  this.approving = false;
                  this.pending = true;
              }
              const api = projects.model.update;
              api.data = {
                  is_verified,
                  is_approved: is_verified,
                  project_id: this.projectId
              };
              this.generateAPI(api)
                  .then(() => {
                      this.$bvToast.toast("Project updated successfully!", {
                          title: "Update",
                          variant: "success",
                          solid: true,
                      });
                      this.isApproved = is_verified;
                      this.pending = false;
                      this.approving = false;
                      this.closeModal();
                  })
                  .catch(() => {
                      this.$bvToast.toast("Project is not updated!", {
                          title: "Update",
                          variant: "danger",
                          solid: true,
                      });
                      this.pending = false;
                      this.approving = false;
                  })
          },
          changeStatus() {
              this.$bvModal.show("project-status-code" + this.projectId);
          },
          closeModal() {
              this.$bvModal.hide("project-status-code" + this.projectId);
          }
      },
  };
  </script>
  