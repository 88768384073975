export const currency = {
  create: {
    url: "currency/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "currency/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  getAll: {
    url: "currency/get",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "currency/delete/",
    method: "DELETE",
    id: null,
  },
};
