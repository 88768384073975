<script>
// import { getFormData } from "../../utils/createForm";
// import { companies } from "../../config/api/companies";
export default {
  props: {
    project: {
      type: Object,
    },
    awards: {
      type: Array,
    }

  },
  data() {

    return {
      addCertificate: false,
      formValues: {
        certificate_document: null,
        certificate_image: null,
      },
    };
  },
  methods: {
    projectMemberVerified() {
      if (!this.project)
        return 0;
      return this.project.team_members.filter((m) => m && m.is_approved).length;
    },
    projectInvolverStar() {
      let owners = this.projectInvolverOwner();
      let contractor = this.projectInvolverContractor();
      let consultant = this.projectInvolverConsultant();
      if (consultant > 0 && owners > 0 && contractor > 0) {
        return 2;
      }
      if (owners > 0 && (contractor > 0 || consultant > 0)) {
        return 1.5;
      }
      if (owners > 0) {
        return 1;
      }
      return 0;
    },
    projectInvolverOwner() {
      if (!this.project)
        return 0;
      return (this.project.team_members.filter((m) => m.user && m.role == "__owner").length) + (this.project.companies.filter((m) => m.user && m.role == "owner").length);
    },
    projectInvolverContractor() {
      if (!this.project)
        return 0;
      return (this.project.companies.filter((m) => m.role == "contractor").length);
    },
    projectInvolverConsultant() {
      if (!this.project)
        return 0;
      return (this.project.companies.filter((m) => m.role == "consultant").length);
    },
    projectMemberVerifiedStar() {
      let count = this.projectMemberVerified();
      if (count >= 4) {
        return 1;
      }
      if (count >= 2) {
        return 0.5;
      }
      return 0;
    },
    openRatingModal() {
      this.$bvModal.show("project-rating-modal");
    },
  },
};
</script>

<template>
  <div v-if="project">
    <div class="text-right mb-4">
      <b-button variant="primary" @click="openRatingModal"> Rating {{  project.rating  }} / 10 </b-button>
    </div>

    <b-modal size="lg" id="project-rating-modal" title="Rating Info" hide-footer>
    
      <div class="row text-center">
        <div class="col-12">
          <h4>Over View ({{  project.rating || 0  }}/10)</h4>
        </div>
        <div class="col-12 row">
          <div class="col-4">
            <h6>Content</h6>
          </div>
          <div class="col-2">
            <h6>Maximum Stars</h6>
          </div>
          <div class="col-3">
            <h6>Count</h6>
          </div>
          <div class="col-3">
            <h6>project Stars</h6>
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-4">Views</div>
          <div class="col-2">2</div>
          <div class="col-3">{{  project.views  }}</div>
          <div class="col-3">{{  project.views > 1000 ? 2 : project.views > 500 ? 1.5 : project.views > 200 ? 1 :project.views > 50 ? 0.5 : 0}}
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-4">Awards </div>
          <div class="col-2">3</div>
          <div class="col-3">{{  awards.length  }}</div>
          <div class="col-3">{{  awards.length >= 3 ? 3 : awards.length  }}</div>
        </div>
        <div class="col-12 row">
          <div class="col-4">Team Member Verified</div>
          <div class="col-2">1</div>
          <div class="col-3">{{  projectMemberVerified()  }}</div>
          <div class="col-3">{{  projectMemberVerifiedStar()  }}
          </div>
        </div>

        <div class="col-12 row">
          <div class="col-4">Certificates </div>
          <div class="col-2">2</div>
          <div class="col-3">{{  project.certificates.length  }}</div>
          <div class="col-3">{{  project.certificates.length >= 2 ? 2 : project.certificates.length  }}</div>
        </div>
   
        <div class="col-12 row">
          <div class="col-4">Project Information Verified</div>
          <div class="col-2">2</div>
          <div class="col-3">
            Owners {{  projectInvolverOwner()  }} <br>
            Contractors {{  projectInvolverContractor()  }} <br>
            Consultants {{  projectInvolverConsultant()  }}
          </div>
          <div class="col-3">{{  projectInvolverStar()  }}
          </div>
        </div>
        <div class="col-12 row">
          <div class="col-4">
            <h6>Total</h6>
          </div>
          <div class="col-2">
            <h6>10</h6>
          </div>
          <div class="col-3">
            <h6>-</h6>
          </div>
          <div class="col-3">
            <h6>{{  project.rating  }}</h6>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style>
.margin-top-custom {
  margin-top: 30px !important;
}
</style>
