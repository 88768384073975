<template>
    <div>

        <div class="pointer">
            <span @click="updateBCC()">Project ID : {{ project.project_id }} </span>
            <project-evidence name="Project ID document" keyname="prj-id-doc" :project="project" :evidences="evidences"></project-evidence>
        </div>
        <b-modal id="edit-project-id" title="BCC" hide-footer>
            <div>
                <FormulateForm name="Update BCC" v-model="values" :schema="schema" @submit="submitHandler">

                    <FormulateInput type="submit" class="float-right mt-2" :disabled="performAction">
                        <span v-if="performAction">
                            <b-spinner class="vueformulate-loader"></b-spinner>
                            <span> Updating... </span>
                        </span>
                        <span v-else>
                            <span> Update </span>
                        </span>

                    </FormulateInput>
                </FormulateForm>
            </div>
        </b-modal>
    </div>
</template>

<script>

import { projects } from "@/config/api/projects";
import ProjectEvidence from "@/components/projects/project-evidence";

export default {
    props: {
        project: {
            type: Object,
        },
        evidences: {
            type: Array
        }
    },
    components: {
        ProjectEvidence
    },
    data() {
        return {
            values: {

            },
            performAction: false,
            schema: [
                {
                    index: 0,
                    type: "text",
                    class: "col-12",
                    validation: "required",
                    name: "new_project_id",
                    label: "BCC",
                },
            ],
        }
    },
    methods: {
        updateBCC() {
            this.values.new_project_id = this.project.project_id;
            this.$bvModal.show("edit-project-id");
        },
        submitHandler(data) {
            console.log(data)
            const api = projects.model.update_bcc;
            api.id = this.project.project_id;
            api.data = data
            this.performAction = true;
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("Project BCC updated successfully", {
                        title: "Project BCC",
                        variant: "success",
                        solid: true,
                    });

                    this.$bvModal.hide("edit-project-id");
                    this.$router.replace({
                        path: `project-info`,
                        query: { id: data.new_project_id },
                    })
                    this.project.project_id = data.new_project_id
                })
                .catch((err) => {
                    this.$bvToast.toast(
                        `Project BCC is not updated, ${err.response.data.message}`,
                        {
                            title: "Project Location",
                            variant: "danger",
                            solid: true,
                        }
                    );
                })
                .finally(() => {
                    this.performAction = false;
                });
        }
    }
}
</script>

<style>

</style>