<script>
import { projects } from "@/config/api/projects";
import { currency } from "@/config/api/currency";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
  props: ["selectedProject", "type"],
  components: { Multiselect },
  computed: {
    schema() {
      var result = Object.keys(this.values).map(function (key, index) {
        return {
          index: index,
          type: key !== "start_date" && key !== "end_date" ? "text" : "date",
          name: key,
          label: key,
          validation: "required",
        }
      });
      return result;
    }
  },
  data() {
    return {
      values: {},
      performAction: false,
      currencyList: [],
      selectedCurrency: ""
    };
  },
  methods: {
    loadAllCurrency() {
      const api = currency.getAll;
      this.generateAPI(api).then((res) => {
        this.currencyList = res.data.currencies;
      })
    },
    init() {
      this.values = {
        project_area: this.selectedProject.project_area || "",
        num_of_floors: this.selectedProject.num_of_floors,
        project_cost: this.selectedProject.project_cost || 0,
        start_date: moment(this.selectedProject.start_date).format("YYYY-MM-DD"),
        end_date: moment(this.selectedProject.end_date).format("YYYY-MM-DD")
      };
      if(this.selectedProject.currency)
      {
        let abbrv =currency
        this.selectedCurrency = {
          abbrv,
          name:abbrv
        }
      }
      this.loadAllCurrency();
    },
    showActionModal() {
      this.init();
      this.$bvModal.show("action-project-specification");
    },
    hideActionModal() {
      this.$bvModal.hide("action-project-specification");
    },
    async submitHandler(data) {
      this.updateProject(data);
    },
    updateProject(data) {
      this.performAction = true;
      const api = projects.model.update;
      if (this.type === "custom") {
        api.data = { ...this.selectedProject, custom_specifications: data };
      }
      else {
        api.data = { ...this.selectedProject, ...data };
        if(this.selectedCurrency && this.selectedCurrency.abbrv)
        {
          api.data['project_currency']=this.selectedCurrency.abbrv
        }
      }
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Project updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch(() => {
          this.$bvToast.toast("Project is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.performAction = false;
          this.$emit("closeModal");
        });
    },
  },
  watch: {

  },
};
</script>

<template>
  <div>
    <h5 class="font-size-14">
      Specifications :
      <button class="action-btn" @click="showActionModal()">
        <i class="ri-edit-line"></i>
      </button>
    </h5>
    <b-modal id="action-project-specification" @hidden="$emit('resetModal')"
      :title="selectedProject ? 'Update Project' : 'Add Project'" hide-footer>
      <FormulateForm name="ProjectAction" v-model="values" :schema="schema" @submit="submitHandler">
        <div class="row col-12 mb-2">
          <label class="typo__label" for="ajax">Currencies</label>
          <multiselect label="name" track-by="abbrv" v-model="selectedCurrency" placeholder="Type to search"
            open-direction="bottom" :searchable="true" :options="currencyList" :multiple="false" :internal-search="true"
            :clear-on-select="true" :close-on-select="true" :max-height="1000" :show-no-results="true"
            :hide-selected="false">
            <template slot="option" slot-scope="props">
              <div class="row col-12">
                {{props.option.name}} - {{props.option.abbrv}}
              </div>

            </template>
          </multiselect>
        </div>
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedProject"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedProject"> Update </span>
            <span v-else> Add </span>
          </span>
        </FormulateInput>
      </FormulateForm>
    </b-modal>
  </div>
</template>


<style>
.payment-duration .formulate-input-group {
  display: flex;
}

.payment-duration .formulate-input-group-item {
  margin: 8px;
}

.payment-duration .formulate-input-label {
  margin-top: -6px;
}
</style>