export const certificates = {
  create: {
    url: "v2/admin/certificate/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "certificate/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  get: {
    url: "admin/certificate/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "certificate/delete/",
    method: "DELETE",
    id: null,
  },
  certificateTypes: {
    create: {
      url: "admin/certificate_type/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "certificate_type/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    get: {
      url: "admin/certificate_type/getAll",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "certificate_type/delete/",
      method: "DELETE",
      id: null,
    },
  },
  getAllForUserById: {
    url: "user/certificate/getAllForUserById",
    method: "GET",
    id: null,
    params: null,
  },
  addUserCertificateAdmin: {
    url: "admin/user/addUserCertificateAdmin",
    method: "POST",
    id: null,
    data: null,
  },
  updateUserCertificateAdmin: {
    url: "admin/user/certificate/update/",
    method: "PUT",
    id: null,
    data: null,
  },
};
