export const awards = {
  projects: {
    create: {
      url: "award/project/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "award/project/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    getById: {
      url: "award/project/getById/",
      method: "GET",
      id: null,
      params: null,
    },
    getAll: {
      url: "admin/award/project/getAll",
      method: "GET",
      id: null,
      params: null,
    },
    getByProjectId: {
      url: "award/project/get/",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "award/project/delete/",
      method: "DELETE",
      id: null,
    },
  },
  users: {
    create: {
      url: "award/user/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "award/user/update/",
      method: "PUT",
      id: null,
      data: null,
    },
    getById: {
      url: "award/user/getById/",
      method: "GET",
      id: null,
      params: null,
    },
    getAll: {
      url: "admin/award/user/getAll",
      method: "GET",
      id: null,
      params: null,
    },
    getByUserId: {
      url: "award/user/get/",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "award/user/delete/",
      method: "DELETE",
      id: null,
    },
  },
  companies: {
    create: {
      url: "award/company/create",
      method: "POST",
      data: null,
    },
    adminCreate: {
      url: "admin/award/company/create",
      method: "POST",
      data: null,
    },
    update: {
      url: "admin/award/company/update/",
      method: "POST",
      id: null,
      data: null,
    },
    getById: {
      url: "award/company/getById/",
      method: "GET",
      id: null,
      params: null,
    },
    getAll: {
      url: "admin/award/company/getAll",
      method: "GET",
      id: null,
      params: null,
    },
    getByCompanyId: {
      url: "award/company/get/",
      method: "GET",
      id: null,
      params: null,
    },
    delete: {
      url: "admin/award/company/delete/",
      method: "DELETE",
      id: null,
    },
  },
  userAwardCreateAdmin:{
    url: "award/user/admin/create",
    method: "POST",
    data:null
  },
  userAwardUpdateAdmin:{
    url: "admin/user/award/update/",
    method: "PUT",
    data:null,
    id:null
  }
};
