<template>
    <span><button class="no-bg-btn" @click="showEvidenceModal"><i class="dripicons-browser-upload"></i></button>
        <b-modal :id="`project-evidence-${keyname}`" title="Evidence" hide-footer>
            <div>
                <div>
                    {{name}}
                </div>
                <div v-if="getFile">
                    <a target="_blank" :href="getFile">{{name}} evidence can be download from here</a>
                   
                </div>
                <FormulateForm name="Update BCC" @submit="submitHandler">
                    <FormulateInput type="file" name="File" :uploader="() => {}" v-model="fileUpload"
                        label="Select evidence to upload" help="Select a file to upload." :validation="'required'" />
                    <FormulateInput type="submit" class="float-right mt-2" :disabled="upload">
                        <span v-if="upload">
                            <b-spinner class="vueformulate-loader"></b-spinner>
                            <span> Uploading... </span>
                        </span>
                        <span v-else>
                            <span> Upload </span>
                        </span>
                    </FormulateInput>
                </FormulateForm>
            </div>
        </b-modal>
    </span>
</template>

<script>
import { getFormData } from '../../utils/createForm';
export default {
    props: {
        project: {
            type: Object
        },
        keyname: {
            type: String
        },
        name: {
            type: String
        },
        evidences: {
            type: Array
        }
    },
    data() {
        return { fileUpload: undefined, upload: false, }
    },
    computed: {
        getFile() {
            
            if (this.keyname && this.evidences) {
                let d= this.evidences.filter((e) => e.field == this.keyname);
                if(d && d.length)
                {
                    return d[0].file_location;
                }
            }
            return '';
        },
    },
    methods: {

        showEvidenceModal() {
            this.$bvModal.show(`project-evidence-${this.keyname}`);
        },
        submitHandler() {
            const data = getFormData({
                project_id: this.project._id,
                file: this.fileUpload.files[0].file,
                field: this.keyname
            })
            const api = {
                url: "project/addEvidence",
                method: "POST",
                data,
            }
            this.upload = true;
            this.generateAPI(api)
                .then((res) => {
                    this.evidences=[
                        ...(this.evidences ||[]),
                        res.data.evidence
                    ];
                     
                    console.log(res);
                    this.$bvModal.hide(`project-evidence-${this.keyname}`);
                    this.$bvToast.toast("added successfully!", {
                        title: "Evidence",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.upload = false;
                });
        }
    }
}
</script>

<style>

</style>