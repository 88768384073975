<script>
// import { certificates } from "@/config/api/certificates";
import { stamps } from "@/config/api/stamps";
import { getFormData } from "@/utils/createForm";
export default {
  props: ["selectedStamp"],
  data() {
    return {
      values: {},
      image: null,
      performAction: false,
      schema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Name",
          validation: "required",
        },
        {
          index: 1,
          type: "text",
          name: "description",
          label: "Description",
          validation: "required",
        },
        {
          index: 2,
          type: "date",
          name: "expiry_date",
          label: "Expiry date",
        },
      ],
    };
  },
  methods: {
    resetModal() {
      this.image = null;
      this.$emit("resetModal");
    },
    async submitHandler(data) {
      const formdata = getFormData({
        file: this.image && this.image.files[0] && this.image.files[0].file,
        name: data.name,
        description: data.description,
        expiry_date: data.expiry_date,
      });
      this.editStamp(formdata);
      //   }
    },
    editStamp(data) {
      this.performAction = true;

      const api = stamps.update;
      api.id = this.values._id;
      api.data = data;
      this.generateMultipartAPI(api)
        .then(() => {
          this.$bvToast.toast("Stamp edited successfully!", {
            title: "Edit",
            variant: "success",
            solid: true,
          });
          this.$bvModal.hide("edit-Stamp");
          this.resetModal();
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Stamp is not edited!", {
            title: "Edit",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedStamp(val) {
      if (val) {
        this.values = {
          ...val,
          expiry_date: this.moment(val.expiry_date).format("YYYY-MM-DD"),
        };
        this.$bvModal.show("edit-Stamp");
      }
    },
  },
};
</script>

<template>
  <b-modal id="edit-Stamp" @hidden="resetModal" title="Edit Stamp" hide-footer>
    <FormulateForm
      name="CertificateAction"
      v-model="values"
      :schema="schema"
      @submit="submitHandler"
    >
      <FormulateInput
        type="image"
        name="Image"
        v-model="image"
        label="Select file to upload"
        help="Select a png or jpg to upload."
        validation="mime:image/jpeg,image/png"
      />
      <FormulateInput type="submit" class="float-right">
        <span v-if="performAction">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Updating... </span>
        </span>
        <span v-else>
          <span> Update </span>
        </span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>
