export const stamps = {
  create: {
    url: "/admin/stamp/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "/user/stamp/update/",
    id: null,
    method: "PUT",
    data: null,
  },
};
