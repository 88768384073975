<template>
  <div>
    <div style="text-align: end" class="row" v-if="project ">
      <div>
        Verification: {{ calcProgress() }}%
      </div>
      <div class="col-lg-9 col-md-12 col-12  pt-2">

        <b-progress :value="calcProgress()" :max="100" class="mb-4" v-b-modal.project-color-bar></b-progress>
      </div>
    </div>

    <b-modal id="project-color-bar" title="Progress Info" hide-footer>
      <div class="row" v-if="project">
        <b-table :items="features || []">
          <template v-slot:cell(value)="row">
            <div>
              <i class="fa fa-check-circle" v-if="row.item.value"></i>
              <i class="fa fa-circle-notch" v-else></i>

            </div>
          </template>
        </b-table>
        <div class="col-12">
          <div>
            Verification: {{ calcProgress() }}%
          </div>
          <div class="col-lg-9 col-md-12 col-12  pt-2">

            <b-progress :value="calcProgress()" :max="100" class="mb-4" v-b-modal.project-color-bar></b-progress>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { projects } from "@/config/api/projects";
import { getFormData } from "@/utils/createForm";
import Swal from "sweetalert2";
export default {
  props: {
    project: {
      type: Object
    }
  },
  created() {
  },
  components: {
  },
  data() {
    return {
      features: [
        {
          label: "Name",
          value: !!this.project.name_en
        },
        {
          label: "Status",
          value: !!this.project.status_code
        },
        {
          label: "Type",
          value: !!this.project.project_type
        },
        {
          label: "Location",
          value: !!this.project.location_en
        },
        {
          label: "Geo-Locations",
          value: !!this.project.geoloc
        },
        {
          label: "Country",
          value: !!this.project.city
        },
        {
          label: "City",
          value: !!this.project.city
        },
        {
          label: "Area",
          value: !!this.project.project_area
        },
        {
          label: "Cost",
          value: !!this.project.project_cost
        },
        {
          label: "Currency",
          value: !!this.project.project_currency
        },
        {
          label: "Start Date",
          value: !!this.project.start_date
        },
        {
          label: "Number of floors",
          value: !!this.project.num_of_floors
        },
        {
          label: "End Date",
          value: !!this.project.end_date
        },
        {
          label: "Custom Specifications",
          value: !!(this.project.custom_specifications)
        },
        {
          label: "Images",
          value: !!(this.project.images && this.project.images.length)
        },
        {
          label: "Cover Image",
          value: !!(this.project.cover_image && this.project.cover_image.length)
        },
        {
          label: "Team Members",
          value: !!(this.project.team_members && this.project.team_members.length)
        },
        {
          label: "Certificates",
          value: !!(this.project.certificates && this.project.certificates.length)
        },
      ],

    };
  },
  methods: {

    calcProgress() {
      if (!this.project)
        return 0;
      return Math.round((((this.basicProgress() + this.locationInfoProgress() + this.specificationInfoProgress()) * (100 / 18))).toFixed(2));
    },
    basicProgress() {
      return (this.project.name_en ? 1 : 0) +
        (this.project.project_type ? 1 : 0) +
        (this.project.status_code ? 1 : 0)
    },
    locationInfoProgress() {
      return (this.project.location_en ? 1 : 0) +
        (this.project.geoloc ? 1 : 0) +
        (this.project.city ? 2 : 0)
    },
    specificationInfoProgress() {
      return (this.project.project_area ? 1 : 0) +
        (this.project.project_cost ? 1 : 0) +
        (this.project.project_currency ? 1 : 0) +
        (this.project.start_date ? 1 : 0) +
        (this.project.num_of_floors ? 1 : 0) +
        ((this.project.custom_specifications) ? 1 : 0) +

        ((this.project.certificates && this.project.certificates.length) ? 1 : 0) +
        ((this.project.team_members && this.project.team_members.length) ? 1 : 0) +
        ((this.project.images && this.project.images.length) ? 1 : 0) +
        (this.project.end_date ? 1 : 0) +
        (this.project.cover_image ? 1 : 0)

    },

    addCompany() {
      if (this.selectedStatus) {
        const data = {
          ...this.formValues,
          project_id: this.$route.query.id,
          company: this.selectedStatus.id,
        };
        const api = projects.addCompany;
        api.data = getFormData(data);
        console.log(api);
        this.submitting = true;
        this.generateAPI(api)
          .then((res) => {
            console.log(res.data);
            this.$bvModal.hide("project-color-bar");
            this.$emit("compnay-added", res.data.project.companies);
            Swal.fire("Added", "company added successfully.", "success");
          })
          .catch((err) => {
            Swal.fire(
              "Not Added!",
              `Company is not added, ${err.response.data.message}`,
              "error"
            );
          })
          .finally(() => {
            this.submitting = false;
          });
      } else {
        Swal.fire(
          "Company is not selected",
          "Please choose a company!",
          "error"
        );
        return;
      }
    },
  },
  watch: {

  }
};
</script>
<style scoped>
.status-code {
  height: 20px;
  width: 20px;
  background-color: #000;
  color: #000;
}
</style>