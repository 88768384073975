<template>
    <div>
        <img v-if="project && project.cover_image" class="img-fluid mx-auto d-block" :src="project.cover_image"
            alt="Project Image" />
        <FormulateForm name="createManufacture" @submit="updateImage">
            <FormulateInput type="image" v-model="cover_image" :uploader="() => {}"
                validation="mime:image/jpeg,image/png,image/gif,image/webp,image/jpg"
                label="Select your Image to upload" help="Please png/ jpg file and then upload">
            </FormulateInput>
            <FormulateInput type="submit">
                <span v-if="updatingImage">
                    <b-spinner class="vueformulate-loader"></b-spinner>
                    <span> Updating... </span>
                </span>
                <span v-else>Update Image</span>
            </FormulateInput>
        </FormulateForm>
    </div>
</template>
<style scoped>
.img-fluid {
    height: 300px
}
</style>
<script>
import { projects } from "@/config/api/projects";
import { getFormData } from "@/utils/createForm";
export default {
    props: {
        project: {
            type: Object
        }
    },
    data() {
        return {
            updatingImage: false,
            cover_image: ''
        }
    },
    methods: {
        updateImage() {
            if (this.cover_image && this.cover_image.files.length > 0) {
                this.updatingImage = true;
                const api = projects.model.uploadCoverImage;
                api.id = this.project._id
                const data = getFormData({
                    id: this.project._id,
                    description:"Cover Image",
                    file: this.cover_image.files[0].file,
                });
                api.data = data;

                this.generateMultipartAPI(api)
                    .then((res) => {
                        this.$bvToast.toast("Project image updated successfully!", {
                            title: "Update",
                            variant: "success",
                            solid: true,
                        });
                        this.project.cover_image = res.data.project.cover_image;
                        this.cover_image = undefined;
                    })
                    .catch(() => {
                        this.$bvToast.toast("Project is not updated!", {
                            title: "Update",
                            variant: "danger",
                            solid: true,
                        });
                    })
                    .finally(() => {
                        this.updatingImage = false;
                    });
            }
        },
    }
}
</script>