export const cities = {
  create: {
    url: "city/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "city/update/",
    method: "PUT",
    id: null,
    data: null,
  },
  get: {
    url: "admin/city/getAll",
    method: "GET",
    id: null,
    params: null,
  },
  delete: {
    url: "city/delete/",
    method: "DELETE",
    id: null,
  },
  cityByCountry: {
    url: "admin/citybycountry/",
    method: "GET",
    id: null,
  },
  searchCity: {
    url: "admin/city/search",
    method: "GET",
    id: null,
    params: null,
  },
  cityById: {
    url: "admin/cityById/",
    method: "GET",
    id: null,
  },
};
