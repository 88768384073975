<script>
import { awards } from "@/config/api/awards";
import { getFormData } from "@/utils/createForm";

/**
 * Add Award Component
 */
export default {
  props: {
    project: {
      type: Object
    }
  },
  data() {
    return {


      selectedUserId: null,
      award_document: null,
      user_id: null,
      award_image: null,
      isFeatured: null,
      loading: false,
      values: {},
      schema: [
        {
          index: 1,
          type: "text",
          name: "name",
          label: "Award Name",
          "validation-messages": { required: "Award name is required" },
          validation: "required",
        },
        {
          index: 2,
          type: "textarea",
          name: "description",
          label: "Description",
        },
        {
          index: 4,
          type: "date",
          name: "date_received",
          class: "col-6",
          label: "Date received",
          "validation-messages": { required: "Date received is required" },
          validation: "required",
        },
      ],
    };
  },
  computed: {},

  methods: {
    async submitHandler(data) {
      const pre_data = {
        project_id:this.project._id,
        ...data,
        file: this.award_document.fileList[0],
        is_verified:true,
      };
      const formdata = getFormData(pre_data);
      this.addAward(formdata);

    },

    addAward(data) {
      this.loading = true;

      const api = awards.projects.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Award added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast("Award is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
 
  },
};
</script>

<template>
  <b-modal id="project-add-award" size="lg" title-class="font-18" title='Add Award' hide-footer>
    <FormulateForm name="AwardAction" v-model="values" :schema="schema" @submit="submitHandler">
      <div class="row">
        <div class="col-8">
          <FormulateInput type="image" name="award_document" v-model="award_document" label="Upload Award Document"
            help="Select a png or jpg to upload." validation='required:mime:image/jpeg,image/png,image/webp'
            :uploader="() => { }" />
        </div>
 
      </div>

      <FormulateInput type="submit" class="float-right">
        <div v-if="loading">
          <b-spinner class="vueformulate-loader"></b-spinner>
          <span> Adding... </span>
        </div>
        <div v-else>
          <span> Add </span>
        </div>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>
